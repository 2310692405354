import { Dropdown, DropdownButton } from "react-bootstrap";

interface IOption {
  id: number;
  name: string;
}

interface IFilterButtonProps {
  titleText: string;
  options: IOption[];
  className?: string;
  setOption: (optionId: number, filterBy: string) => void;
}

export const FilterButton: React.FC<IFilterButtonProps> = ({
  titleText,
  options,
  className,
  setOption,
}) => {
  const handleSelectFilter = (optionId: number) => {
    setOption(optionId, titleText);
  };
  return (
    <DropdownButton title={titleText} className={className} variant="secondary">
      <Dropdown.Item onClick={() => handleSelectFilter(0)}>Todos</Dropdown.Item>
      {options.map((option) => (
        <Dropdown.Item
          key={option.id}
          onClick={() => handleSelectFilter(option.id)}
        >
          {option.name}
        </Dropdown.Item>
      ))}
    </DropdownButton>
  );
};
