import Radio from "../../../../../components/RadioButton";
import { Col, Row } from "react-bootstrap";
import IMRoleResponse from "../../../../../models/Role/RoleResponse";
import IMSubscriptionResponse from "../../../../../models/Subscription/SubscriptionResponse";
import IMUserResponse from "../../../../../models/User/UserResponse";

interface IRoleSubscriptionProps {
  rolesResp: IMRoleResponse[];
  subscriptionsResp: IMSubscriptionResponse[];
  user?: IMUserResponse;
  onRoleSelect: (roleId: number) => void;
  onSubscriptionSelect: (subscriptionId: number) => void;
  roleSelected: number | undefined;
  subscriptionSelected: number | undefined;
}

export const RoleSubscription: React.FC<IRoleSubscriptionProps> = ({
  rolesResp,
  subscriptionsResp,
  user,
  onRoleSelect,
  onSubscriptionSelect,
  roleSelected,
  subscriptionSelected,
}) => {
  const handleSelectRole = (event: React.ChangeEvent<HTMLInputElement>) => {
    const role = parseInt(event.target.value);
    onRoleSelect(role);
  };
  const handleSelectSubscription = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const subscription = parseInt(event.target.value);

    onSubscriptionSelect(subscription);
  };

  return (
    <div className="">
      <div className="">
        Usuario: <b>{user?.name}</b>
      </div>
      <div>
        Email: <b>{user?.email}</b>
      </div>
      <hr />
      <Row className="ms-3 me-3">
        <Col>
          <h5 className="mb-3">Elegir Rol</h5>
          {rolesResp.map((role: IMRoleResponse) => (
            <div key={role.id} className="form-check">
              <Radio
                className="form-check-input"
                name="role_id"
                id={role.name}
                value={role.id.toString()}
                checked={role.id === roleSelected}
                onChange={handleSelectRole}
              />
              <label className="form-check-label" htmlFor="role_selected">
                {role.name}
              </label>
            </div>
          ))}
        </Col>
        <Col>
          <h5 className="mb-3">Elegir Suscripción</h5>
          {subscriptionsResp.map((subscription: IMSubscriptionResponse) => (
            <div key={subscription.id} className="form-check">
              <Radio
                className="form-check-input"
                name="subscription_id"
                id={subscription.name}
                value={subscription.id.toString()}
                checked={subscription.id === subscriptionSelected}
                onChange={handleSelectSubscription}
              />
              <label
                className="form-check-label"
                htmlFor="subscriptionSelected"
              >
                {subscription.name}
              </label>
            </div>
          ))}
        </Col>
      </Row>
    </div>
  );
};
