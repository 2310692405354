import Table from "react-bootstrap/Table";
import { Dropdown, DropdownButton } from "react-bootstrap";
import Pagination from "../Pagination";

import IToolTip from "../../models/ToolTip";
import { ChangeEvent, Dispatch, useCallback, useMemo } from "react";
import IMShowingData from "../../models/ShowingData";
import { FaEllipsisV, FaSearch } from "react-icons/fa";

interface ITableProps {
  list: any[];
  colNames: any[];
  tooltipsList?: IToolTip[];
  loadData?: () => Promise<void>;
  page?: number;
  per_page?: number;
  showingData?: IMShowingData;
  setPage?: Dispatch<React.SetStateAction<number>>;
  modelName: string;
  setSearchTerm?: Dispatch<React.SetStateAction<string>>;
  infoColNumber: number;
  notSearchBar?: boolean;
  noAction?: boolean;
  filterTerms?: string;
}

// -------------------------------------------------------------------------- //
// ------------- This is for loading data in a custom table: ---------------- //
// -------------------------------------------------------------------------- //

// *list -> array of elements to show in table
// *colNames -> headers for column Tables. One may be "Actions", include
//    several columns and have tooltips.i.e.
//    { name: "Acciones", colSpan: 1, className: "text-center" },
// *tooltipsList -> at the end of each row, may be some actions, and tooltips
//    on them.example:
//  const [tooltips] = useState<ITooltip[]>([
//   {
//     name: "Eliminar", // tooltip text to show
//     className: "btn btn-outline-danger", // bootstrap class
//     handle: handleDeleteItem, // custom action
//     icon: MdDelete, // react icon to show
//   },
// ]);
// *loadData -> custom function fetching/getting data to refresh data
//    when needed
//    page, per_page, set_page -> info for pagination
//    showingData -> for example:
//  const dataShowing: IMShowingData = {
//       fromData: response.data.from,
//       toData: response.data.to,
//       totalData: response.data.total,
//       lastPage: response.data.last_page,
//     };
//     setShowingData(dataShowing);
// *modelName -> text to add in search placeholder
// *setSearchTerm -> useState string type.
// *infoColNumber -> Number of columns to show, without including Action columns
// *notSearchBar -> set to false if you want a search bar in the table

// -------------------------------------------------------------------------- //
// -------------------------------------------------------------------------- //

export const TableLocal: React.FC<ITableProps> = ({
  list,
  colNames,
  tooltipsList,
  loadData,
  page,
  per_page,
  showingData,
  setPage,
  modelName,
  setSearchTerm,
  infoColNumber,
  notSearchBar,
  noAction,
  filterTerms,
}) => {
  const handleAction = useCallback((obj: Object, tooltip: IToolTip) => {
    tooltip.handle(obj);
  }, []);

  const searchPlaceholder = useMemo(
    () => "Buscar por " + filterTerms,
    [filterTerms]
  );

  const handleSearch = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (setSearchTerm) {
        setSearchTerm(e.target.value);
      }
    },
    [setSearchTerm]
  );

  return (
    <div className="container">
      {notSearchBar ? (
        ""
      ) : (
        <div className="input-group mb-3">
          <div className="form-outline col me-3">
            <input
              type="search"
              id="form1"
              className="form-control"
              placeholder={searchPlaceholder}
              onChange={handleSearch}
            />
          </div>
          <div className="pe-3 pt-1">
            <FaSearch />
          </div>
        </div>
      )}
      {list.length > 0 && (
        <>
          <div className="">
            <Table striped bordered hover className="align-middle">
              <thead className="table-dark">
                <tr>
                  {colNames.map((colN, i) => (
                    <th
                      key={i}
                      colSpan={colN.colSpan}
                      className={colN.className}
                    >
                      {colN.name.toUpperCase()}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {/* iterate over each row (an entry in table) */}
                {Object.values(list).map((obj, index) => (
                  <tr key={obj.id || obj.sequence || index}>
                    {Object.values(obj)
                      .slice(0, infoColNumber)
                      .map((value: any, index2) => (
                        <td key={index2}>{value}</td>
                      ))}
                    {!noAction && (
                      <td>
                        <DropdownButton
                          title={<FaEllipsisV />}
                          variant="secondary"
                          id={`dropdown-button-${index}`}
                          className="text-center"
                        >
                          {/* Iterate over available options */}
                          {tooltipsList?.map((tooltip, i) => (
                            <Dropdown.Item
                              key={i}
                              onClick={() => handleAction(obj, tooltip)}
                            >
                              {tooltip.name}
                            </Dropdown.Item>
                          ))}
                        </DropdownButton>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
          <div className="d-flex justify-content-end">
            {page && showingData && setPage && (
              <Pagination
                activePage={page}
                itemsCountPerPage={per_page ?? 10}
                totalItemsCount={showingData.totalData}
                onChange={setPage}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};
