import React from "react";
import { Routes, Route } from "react-router-dom";
import { Login } from "../pages/Login";
import { Register } from "../pages/Register";
import UnauthenticatedRoute from "../components/UnauthenticatedRoute";
import { Users } from "../pages/Users";
import PrivateRoute from "../components/PrivateRoute";

const routes: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<UnauthenticatedRoute component={Login} />} />
      <Route
        path="/login"
        element={<UnauthenticatedRoute component={Login} />}
      />
      <Route
        path="/registro"
        element={<UnauthenticatedRoute component={Register} />}
      />
      <Route path="/usuarios" element={<PrivateRoute component={Users} />} />
    </Routes>
  );
};

export default routes;
