import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { AuthProvider } from "./context/AuthContext";
import { NavbarMain } from "./components/NavbarMain";
import { BrowserRouter } from "react-router-dom";
import LocalRoutes from "./routes";

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <NavbarMain />
        <hr />
        <LocalRoutes />
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
