import React from "react";
import Input from "../../../../../components/Input";

export const CompleteEdit = () => {
  return (
    <>
      <div className="mt-3">
        <label htmlFor="name">
          <span>Nombre: *</span>
        </label>
        <Input
          className=" form-control"
          placeholder="Escriba el nombre"
          type="text"
          name="name"
        ></Input>
      </div>
      <div className="mt-3">
        <label htmlFor="name">
          <span>Email: *</span>
        </label>
        <Input
          className=" form-control"
          placeholder="Escriba el mail"
          type="text"
          name="email"
        ></Input>
      </div>
    </>
  );
};
