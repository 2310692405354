import { useField } from "@unform/core";
import { InputHTMLAttributes, useEffect, useRef } from "react";

interface RadioProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  value: string;
  checked?: boolean;
}

const Radio = ({ name, value, checked, ...rest }: RadioProps) => {
  const radioRef = useRef<HTMLInputElement>(null);

  const { registerField, fieldName, error } = useField(name);

  useEffect(() => {
    registerField<string>({
      name: fieldName,
      ref: radioRef.current,
      getValue: (ref) => (ref.checked ? ref.value : ""),
      setValue: (ref, valueToSet) => {
        ref.checked =
          ref.value === valueToSet || (ref.value === "" && valueToSet === null);
      },
    });
  }, [fieldName, registerField, value]);

  return (
    <>
      <input
        ref={radioRef}
        type="radio"
        value={value}
        name={name}
        checked={checked}
        {...rest}
      />
      {error && <span className="text-danger small">{error}</span>}
    </>
  );
};

export default Radio;
