import styled from "styled-components";
import { Navbar, NavDropdown } from "react-bootstrap";

export const CustomDropdown = styled(NavDropdown)`
  top: 0;
  right: 0;
  max-width: 8cqh;
  text-overflow: ellipsis;
  transform-origin: top right;
  background-color: #0ab2e6;

  .dropdown-menu {
    left: auto;
    right: 0;
    transform: translateX(100%);
    background-color: #0ab2e6;
  }
`;

export const CustomNavbar = styled(Navbar)`
  background-color: #0ab2e6;
  /* color: #ffffff; */

  .nav-link .nav-dropdown {
    background-color: #0ab2e6;
    color: #ffffff;
  }

  /* .navbar-nav .nav-link,
  .navbar-nav .nav-item .nav-dropdown-toggle {
    color: #ffffff !important;
  } */
`;

export const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 10px;
`;
