import { useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import Swal from "sweetalert2";
import * as Yup from "yup";

import { useAuth } from "../../context/AuthContext";
import Input from "../../components/Input";

// ----------------------------------------------------------------------------
//                                  INTERFACES
// ----------------------------------------------------------------------------

interface ILogin {
  id?: number;
  name?: string;
  email: string;
  password: string;
  password_confirmation?: string;
}

export const Login = () => {
  const navigate = useNavigate();
  const formRef = useRef<FormHandles>(null);

  const { login } = useAuth();

  const handleSubmit = useCallback(
    async (data: ILogin) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          email: Yup.string().required("Por favor escriba el correo"),
          password: Yup.string().required("Por favor escriba la contraseña"),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const { email, password } = {
          email: data.email,
          password: data.password,
        };

        await login({
          email: email,
          password: password,
        });
        navigate("/usuarios");
      } catch (err) {
        console.log(err);
        Swal.fire({
          title: "Error",
          text: "Los datos son incorrectos, intente nuevamente",
          icon: "error",
        });
      }
    },
    [login, navigate]
  );

  return (
    <Form ref={formRef} onSubmit={handleSubmit} placeholder={undefined}>
      <div className="container mt-5">
        <div className="row justify-content-center">
          <div className="col-md-6">
            <div className="card">
              <div className="card-body">
                <h2 className="text-center">Iniciar Sesión</h2>
                <div className="form-group mt-3">
                  <label htmlFor="email">Email:</label>
                  <Input
                    className="form-control"
                    type="text"
                    name="email"
                    placeholder="Escriba el email"
                  />
                </div>
                <div className="form-group mt-3">
                  <label htmlFor="password">Contraseña:</label>
                  <Input
                    className="form-control"
                    type="password"
                    name="password"
                    placeholder="Escriba la contraseña"
                  />
                </div>
                <div className="text-center mt-3">
                  <button className="btn btn-primary btn-block" type="submit">
                    Iniciar Sesión
                  </button>
                </div>
                <p className="mt-3 text-center">
                  ¿No tienes una cuenta? <a href="/registro">Regístrate aquí</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
};
