import Container from "react-bootstrap/Container";
import { useNavigate } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { useCallback } from "react";
import Swal from "sweetalert2";

import { useAuth } from "../../context/AuthContext";
import { CustomNavbar } from "./styles";

export const NavbarMain = () => {
  const navigate = useNavigate();
  const { user, logout } = useAuth();

  const handleLogout = useCallback(async () => {
    try {
      logout();
    } catch (err) {
      console.log(err);
      Swal.fire({
        title: "Error",
        text: "Usted no se ha autenticado",
        icon: "error",
      });
      navigate("/login");
    }
  }, [logout, navigate]);

  return (
    <CustomNavbar expand="lg">
      <Container>
        <Navbar.Brand href={`/`}>Know Your Customer</Navbar.Brand>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          className="container row"
        />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav className="">
            {user ? (
              <>
                <Navbar.Text>Hola {user.name}</Navbar.Text>
                <Nav.Link
                  id="basic-nav-dropdown"
                  onClick={handleLogout}
                  className="nav-link ms-5 fw-bold"
                >
                  Salir
                </Nav.Link>
              </>
            ) : (
              <>
                <Nav.Link
                  id="basic-nav-dropdown"
                  href={`/login`}
                  className="nav-link"
                >
                  Login
                </Nav.Link>
                <Nav.Link
                  id="basic-nav-dropdown"
                  href={`/registro`}
                  className="nav-link ms-5"
                >
                  Registrarse
                </Nav.Link>
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </CustomNavbar>
  );
};
