import { useCallback, useRef, useState } from "react";
import { FormHandles } from "@unform/core";
import { Modal } from "react-bootstrap";
import { Form } from "@unform/web";
import * as Yup from "yup";

import IMUserResponse from "../../../../models/User/UserResponse";
import { api } from "../../../../services/api";
import { CompleteEdit } from "./CompleteEdit";
import { RoleSubscription } from "./RoleSubscription";
import IMRoleResponse from "../../../../models/Role/RoleResponse";
import IMSubscriptionResponse from "../../../../models/Subscription/SubscriptionResponse";
import Swal from "sweetalert2";

interface IEditUserProps {
  user?: IMUserResponse;
  handleClose: () => void;
  loadData?: () => void;
  roleSubscription?: boolean;
  rolesResp: IMRoleResponse[];
  subscriptionsResp: IMSubscriptionResponse[];
}

interface Errors {
  [key: string]: string;
}

export const EditUser: React.FC<IEditUserProps> = ({
  user,
  handleClose,
  loadData,
  roleSubscription,
  rolesResp,
  subscriptionsResp,
}) => {
  const formRef = useRef<FormHandles>(null);
  const [roleSelected, setRoleSelected] = useState<number | undefined>(
    user?.role_id
  );
  const [subscriptionSelected, setSubscriptionSelected] = useState<
    number | undefined
  >(user?.subscription_id);

  const handleSubmit = useCallback(
    async (data: IMUserResponse) => {
      try {
        formRef.current?.setErrors({});
        let schema;
        if (!roleSubscription) {
          schema = Yup.object().shape({
            name: Yup.string().required("Por favor escriba el nombre"),
            email: Yup.string().required("Por favor escriba el email"),
          });
        } else {
          schema = Yup.object().shape({
            role_id: Yup.string(),
            subscription_id: Yup.string(),
          });
        }

        await schema.validate(data, {
          abortEarly: false,
        });
        data.role_id = roleSelected ?? user?.role_id;
        data.subscription_id = subscriptionSelected ?? user?.subscription_id;
        const formData = {
          name: data.name,
          email: data.email,
          role_id: data.role_id,
          subscription_id: data.subscription_id,
        };

        if (user) {
          if (!roleSubscription) {
            await api.put(`admin/users/${user.id}`, formData);
            Swal.fire({
              title: "Usuario modificado",
              text: "Usuario Modificado con éxito",
              icon: "success",
            });
          } else {
            await api.put(`admin/users/permissions/${user.id}`, formData);
            Swal.fire({
              title: "Rol o Suscripción",
              text: "Rol o Suscripción asignado con éxito",
              icon: "success",
            });
          }
        }
        handleClose();
        const load = loadData;

        if (load) {
          loadData();
        }
      } catch (err: any) {
        console.log(err);
        const validationErrors: Errors = {};
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach((error: any) => {
            validationErrors[error.path as string] = error.message;
          });
          formRef.current?.setErrors(validationErrors);
        } else {
          console.log(err);
          Swal.fire({
            title: "Error",
            text: "Los datos son incorrectos, intente nuevamente",
            icon: "error",
          });
        }
      }
    },
    [
      handleClose,
      loadData,
      roleSelected,
      roleSubscription,
      subscriptionSelected,
      user,
    ]
  );

  return (
    <Form
      ref={formRef}
      onSubmit={handleSubmit}
      initialData={user}
      placeholder={undefined}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {roleSubscription
            ? "Subscripcion y Rol del Usuario"
            : "Actualizar Usuario"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!roleSubscription ? (
          <CompleteEdit />
        ) : (
          <RoleSubscription
            rolesResp={rolesResp}
            subscriptionsResp={subscriptionsResp}
            user={user}
            onRoleSelect={setRoleSelected}
            onSubscriptionSelect={setSubscriptionSelected}
            roleSelected={roleSelected}
            subscriptionSelected={subscriptionSelected}
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-secondary" onClick={handleClose}>
          Cerrar
        </button>
        <button className="btn btn-primary" type="submit">
          Guardar
        </button>
      </Modal.Footer>
    </Form>
  );
};
