import React, { useCallback, useEffect, useState, useMemo } from "react";
import { ImArrowRight } from "react-icons/im";
import { MdDelete } from "react-icons/md";
import { Modal } from "react-bootstrap";
import { FaEdit } from "react-icons/fa";
import Swal from "sweetalert2";

import { TableLocal } from "../../components/TableLocal";
import { api } from "../../services/api";

import IMSubscriptionResponse from "../../models/Subscription/SubscriptionResponse";
import IMRoleResponse from "../../models/Role/RoleResponse";
import IMUserResponse from "../../models/User/UserResponse";
import IMShowingData from "../../models/ShowingData";

import { ModalManageUser } from "./ModalManageUser";
import { ModalSeeUser } from "./ModalSeeUser";
import { FilterButton } from "./FilterButton";
import { useAuth } from "../../context/AuthContext";
import { useConsultations } from "../../hooks/useConsultations";
import { CustomModal } from "./styles";

interface IOption {
  id: number;
  name: string;
}
interface IFilters {
  filterBy: string;
  options: IOption[];
  setOption: (optionId: number, filterBy: string) => void;
}

export const Users = () => {
  const { user } = useAuth();
  const { loadConsultations, consultations } = useConsultations();

  const modelName = useMemo(() => "Usuario", []);
  const filterTerms = useMemo(() => "Nombre del usuario y Correo", []);
  const colNames = useMemo(
    () => [
      { name: "Id" },
      { name: modelName },
      { name: "Email" },
      { name: "Rol" },
      { name: "Suscripción" },
      {
        name: "Actualizado",
        colSpan: 2,
        className: "text-center",
      },
      { name: "Acciones", colSpan: 1, className: "text-center" },
    ],
    [modelName]
  );

  // -------------------------------- useState Model
  const [usersResp, setUsersResp] = useState<IMUserResponse[]>([]);
  const [infoColNumber, setInfoColNumber] = useState<number>(0);

  const [rolesResp, setRolesResp] = useState<IMRoleResponse[]>([]);
  const [currentRole, setCurrentRole] = useState<number | undefined>();
  const [subscriptionsResp, setSubscriptionsResp] = useState<
    IMSubscriptionResponse[]
  >([]);
  const [currentSubscription, setCurrentSubscription] = useState<
    number | undefined
  >();

  const [filterOptions, setFilterOptions] = useState<IFilters[]>([]);
  const [roleSubscription, setRoleSubscription] = useState<boolean>(false);

  // -------------------------------- For pagination
  const per_page = useMemo(() => 8, []);
  const [page, setPage] = useState(1);
  const [showingData, setShowingData] = useState<IMShowingData>({
    fromData: 1,
    toData: 1,
    totalData: 1,
  });

  // -------------------------------- useState Manage
  const [show, setShow] = useState(false);
  const [showSee, setShowSee] = useState(false);

  const [currentUser, setCurrentUser] = useState<IMUserResponse>();
  const [erase, setErase] = useState<boolean>(false);

  // -------------------------------- load Data in table
  const [searchTerm, setSearchTerm] = useState<string>("");

  const loadData = useCallback(async () => {
    try {
      const roleFilter = currentRole !== 0 ? currentRole : null;
      const subscriptionFilter =
        currentSubscription !== 0 ? currentSubscription : null;

      const response = await api.get(`admin/users`, {
        params: {
          page,
          per_page,
          search: searchTerm,
          role: roleFilter,
          subscription: subscriptionFilter,
        },
      });

      const data: IMUserResponse[] = response.data.data.map(
        (row: IMUserResponse) => {
          const rowDate = row.updated_at ? row.updated_at : row.created_at;
          let date = "";
          if (rowDate) {
            const dateParts = rowDate.split(/[T.]/);
            date = `${dateParts[0]} ${dateParts[1]}`;
          }
          const isThereUser = row.user_update
            ? row.user_update
            : row.user_create;

          const last_user = isThereUser ? isThereUser.name : " - ";

          return {
            id: row.id,
            name: row.name,
            email: row.email,
            role_name: row.role?.name ?? " - ",
            subscription_name: row.subscription?.name ?? " - ",
            date: date,
            last_user: last_user,
            role_id: row.role_id,
            subscription_id: row.subscription_id,
            role: row.role,
            subscription: row.subscription,
          };
        }
      );
      setUsersResp(data);
      setInfoColNumber(7);

      const dataShowing: IMShowingData = {
        fromData: response.data.from,
        toData: response.data.to,
        totalData: response.data.total,
        lastPage: response.data.last_page,
      };
      setShowingData(dataShowing);
    } catch (err: any) {
      console.log(err);
      Swal.fire({
        title: "Error User",
        text: err.response.data.message,
        icon: "error",
      });
    }
  }, [currentRole, currentSubscription, page, per_page, searchTerm]);

  const handleSetOption = (optionId: number, filterBy: string) => {
    if (filterBy === "Rol") {
      setCurrentRole(optionId);
    } else if (filterBy === "Suscripcion") {
      setCurrentSubscription(optionId);
    }
  };
  const loadRolesSubscriptions = useCallback(async () => {
    try {
      const responseRoles = await api.get(`users/roles/all`);
      const responseSubscriptions = await api.get(`users/subscriptions/all`);

      const dataRoles: IMRoleResponse[] = responseRoles.data;
      const dataSubscriptions: IMSubscriptionResponse[] =
        responseSubscriptions.data;
      setRolesResp(dataRoles);
      setSubscriptionsResp(dataSubscriptions);

      const filterByRole: IFilters = {
        filterBy: "Rol",
        options: dataRoles,
        setOption: handleSetOption,
      };
      const filterBySubscription: IFilters = {
        filterBy: "Suscripcion",
        options: dataSubscriptions,
        setOption: handleSetOption,
      };
      console.log(
        dataRoles,
        "dataRoles",
        Array.isArray(dataRoles),
        "is array?"
      );

      const filterByOptions: IFilters[] = [filterByRole, filterBySubscription];
      setFilterOptions(filterByOptions);
    } catch (err: any) {
      console.log(err);
      Swal.fire({
        title: "Error Role",
        text: err.response.data.message,
        icon: "error",
      });
    }
  }, []);

  useEffect(() => {
    user && loadData();
    user && loadRolesSubscriptions();
  }, [loadData, loadRolesSubscriptions, user]);

  // -------------------------------- Manage Users (Modal)
  const handleCloseModal = () => setShow(false);
  const handleShowModal = useCallback(() => setShow(true), []);

  const handleCloseModalSee = () => setShowSee(false);
  const handleShowModalSee = useCallback(() => setShowSee(true), []);

  const handleEditUser = useCallback(
    (user: IMUserResponse) => {
      setCurrentUser(user);
      setRoleSubscription(false);
      handleShowModal();
    },
    [handleShowModal]
  );
  const handleDeleteUser = useCallback(
    (user: IMUserResponse) => {
      console.log(user, "user en el handleDelete");
      setErase(true);
      setCurrentUser(user);
      setRoleSubscription(false);
      handleShowModal();
    },
    [handleShowModal]
  );

  const handleSeeUser = useCallback(
    (user: IMUserResponse) => {
      setCurrentUser(user);
      setRoleSubscription(false);
      loadConsultations(user.id);
      handleShowModalSee();
    },
    [handleShowModalSee, loadConsultations]
  );

  const handleAsignRoleandSubscription = useCallback(
    (user: IMUserResponse) => {
      setCurrentUser(user);
      setRoleSubscription(true);
      handleShowModal();
    },
    [handleShowModal]
  );

  // -------------------------------- useState Tooltip Data
  const tooltips = useMemo(
    () => [
      {
        name: "Ver",
        className: "btn btn-outline-success",
        handle: handleSeeUser,
        icon: ImArrowRight,
      },
      {
        name: "Asignar Rol y Suscripcion",
        className: "btn btn-outline-danger",
        handle: handleAsignRoleandSubscription,
        icon: ImArrowRight,
      },
      {
        name: "Editar Usuario",
        className: "btn btn-outline-primary",
        handle: handleEditUser,
        icon: FaEdit,
      },
      {
        name: "Eliminar",
        className: "btn btn-outline-danger",
        handle: handleDeleteUser,
        icon: MdDelete,
      },
    ],
    [
      handleAsignRoleandSubscription,
      handleDeleteUser,
      handleEditUser,
      handleSeeUser,
    ]
  );

  return (
    <div className="container">
      <h2>{modelName}s</h2>
      <p>
        Este es un listado de todos los {modelName.toLowerCase()}s disponibles.
      </p>
      <hr />
      <br />
      <div className="ms-3 mb-3 row">
        <span className="col-md-3">Filtrar por: </span>
        <div className="col d-flex justify-content-end">
          {filterOptions.map((filter) => (
            <span key={filter.filterBy} className="me-3">
              <FilterButton
                titleText={filter.filterBy}
                options={filter.options}
                setOption={filter.setOption}
              />
            </span>
          ))}
        </div>
      </div>
      <TableLocal
        list={usersResp}
        colNames={colNames}
        tooltipsList={tooltips}
        loadData={loadData}
        page={page}
        per_page={per_page}
        showingData={showingData}
        setPage={setPage}
        modelName={modelName}
        setSearchTerm={setSearchTerm}
        infoColNumber={infoColNumber}
        filterTerms={filterTerms}
      />

      <Modal show={show} onHide={handleCloseModal}>
        <ModalManageUser
          handleClose={handleCloseModal}
          loadData={loadData}
          user={currentUser}
          erase={erase}
          setErase={setErase}
          modelName={modelName}
          rolesResp={rolesResp}
          subscriptionsResp={subscriptionsResp}
          roleSubscription={roleSubscription}
        />
      </Modal>
      <CustomModal
        show={showSee}
        onHide={handleCloseModalSee}
        dialogClassName=""
      >
        <ModalSeeUser
          handleClose={handleCloseModalSee}
          user={currentUser}
          modelName={modelName}
          consultations={consultations}
        />
      </CustomModal>
    </div>
  );
};
