import { Dispatch, SetStateAction } from "react";

import IMUserResponse from "../../../models/User/UserResponse";
import { DeleteUser } from "./DeleteUser";
import { EditUser } from "./EditUser";
import IMRoleResponse from "../../../models/Role/RoleResponse";
import IMSubscriptionResponse from "../../../models/Subscription/SubscriptionResponse";
// ----------------------------------------------------------------------------
//                                  INTERFACES
// ----------------------------------------------------------------------------

// -------------------------------- For props
interface IManageUserProps {
  handleClose: () => void;
  user?: IMUserResponse;
  erase?: boolean;
  roleSubscription?: boolean;
  setErase: Dispatch<SetStateAction<boolean>>;
  loadData?: () => void;
  modelName: string;
  rolesResp: IMRoleResponse[];
  subscriptionsResp: IMSubscriptionResponse[];
}

export const ModalManageUser: React.FC<IManageUserProps> = ({
  handleClose,
  user,
  erase,
  roleSubscription,
  setErase,
  loadData,
  modelName,
  rolesResp,
  subscriptionsResp,
}) => {
  return (
    <>
      {!erase ? (
        <EditUser
          handleClose={handleClose}
          loadData={loadData}
          user={user}
          roleSubscription={roleSubscription}
          rolesResp={rolesResp}
          subscriptionsResp={subscriptionsResp}
        />
      ) : (
        <DeleteUser
          user={user}
          setErase={setErase}
          handleClose={handleClose}
          loadData={loadData}
          modelName={modelName}
        />
      )}
    </>
  );
};
