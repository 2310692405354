import { useCallback, useState } from "react";
import IMConsultationResponse from "../models/Consultation/ConsultationResponse";
import { api } from "../services/api";

export const useConsultations = () => {
  const [consultations, setConsultations] = useState<IMConsultationResponse>();
  const loadConsultations = useCallback(async (userId: number) => {
    try {
      const response = await api.get(`admin/consultations/count/${userId}`);
      setConsultations(response.data);
    } catch (err) {
      console.log(err);
    }
  }, []);

  return {
    loadConsultations,
    consultations,
  };
};
