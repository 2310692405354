import { useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { FormHandles } from "@unform/core";
import { Form } from "@unform/web";
import Swal from "sweetalert2";
import * as Yup from "yup";

import { useAuth } from "../../context/AuthContext";
import Input from "../../components/Input";
import { api } from "../../services/api";

// ----------------------------------------------------------------------------
//                                  INTERFACES
// ----------------------------------------------------------------------------

interface IRegister {
  id?: number;
  name?: string;
  email: string;
  password: string;
  password_confirmation: string;
}

interface Errors {
  [key: string]: string;
}

export const Register = () => {
  const { login } = useAuth();
  const navigate = useNavigate();
  const formRef = useRef<FormHandles>(null);

  const handleSubmit = useCallback(
    async (data: IRegister) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          name: Yup.string().required("Por favor escriba su nombre completo"),
          email: Yup.string().email().required("Por favor escriba el correo"),
          password: Yup.string().required("Por favor escriba la contraseña"),
          password_confirmation: Yup.string().required(
            "Debe coincidir con la contraseña"
          ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const { name, email, password, password_confirmation } = {
          name: data.name,
          email: data.email,
          password: data.password,
          password_confirmation: data.password_confirmation,
        };

        const response: any = await api.post(`auth/register`, {
          name,
          email,
          password,
          password_confirmation,
        });

        Swal.fire({
          title: "Bienvenido",
          text: "Usted se ha registrado exitosamente " + response.data.message,
          icon: "success",
        });
        login({ email: email, password: password });
        navigate("/");
      } catch (err: any) {
        const validationErrors: Errors = {};
        if (err instanceof Yup.ValidationError) {
          err.inner.forEach((error: any) => {
            validationErrors[error.path as string] = error.message;
          });
          formRef.current?.setErrors(validationErrors);
        } else {
          console.log(err);
          Swal.fire({
            title: "Error",
            text:
              "Los datos son incorrectos, intente nuevamente (" +
              err.response.data.password[0] +
              ")",
            icon: "error",
          });
        }
      }
    },
    [login, navigate]
  );

  return (
    <Form ref={formRef} onSubmit={handleSubmit} placeholder={undefined}>
      <div className="container mt-5">
        <div className="row justify-content-center">
          <div className="col-md-6">
            <div className="card">
              <div className="card-body">
                <h2 className="text-center">Registro</h2>
                <div className="form-group mt-3">
                  <label htmlFor="name">Nombre completo:</label>
                  <Input
                    className="form-control"
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Escriba su nombre completo"
                  />
                </div>
                <div className="form-group mt-3">
                  <label htmlFor="email">Email:</label>
                  <Input
                    className="form-control"
                    type="text"
                    id="email"
                    name="email"
                    placeholder="Escriba el email"
                  />
                </div>
                <div className="form-group mt-3">
                  <label htmlFor="password">Contraseña:</label>
                  <Input
                    className="form-control"
                    type="password"
                    id="password"
                    name="password"
                    placeholder="Escriba la contraseña"
                  />
                </div>
                <div className="form-group mt-3">
                  <label htmlFor="password_confirmation">
                    Confirme la contraseña:
                  </label>
                  <Input
                    className="form-control"
                    type="password"
                    id="password_confirmation"
                    name="password_confirmation"
                    placeholder="Escriba la contraseña"
                  />
                </div>
                <div className="text-center mt-3">
                  <button className="btn btn-primary btn-block" type="submit">
                    Registrarse
                  </button>
                </div>
                <p className="mt-3 text-center">
                  ¿Ya tienes una cuenta? <a href="/login">Inicia sesión aquí</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Form>
  );
};
