import React from "react";

interface PaginationProps {
  activePage: number;
  itemsCountPerPage: number;
  totalItemsCount: number;
  onChange: (page: number) => void;
}

const Pagination2: React.FC<PaginationProps> = ({
  activePage,
  itemsCountPerPage,
  totalItemsCount,
  onChange,
}) => {
  const totalPages = Math.ceil(totalItemsCount / itemsCountPerPage);

  const handlePageChange = (newPage: number) => {
    if (newPage !== activePage) {
      onChange(newPage);
    }
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];

    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(
        <li key={i} className={`page-item ${i === activePage ? "active" : ""}`}>
          <button className="page-link" onClick={() => handlePageChange(i)}>
            {i}
          </button>
        </li>
      );
    }

    return pageNumbers;
  };

  return (
    <nav aria-label="Page navigation">
      <ul className="pagination">{renderPageNumbers()}</ul>
    </nav>
  );
};

export default Pagination2;
