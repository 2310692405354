import { Navigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import { FC } from "react";

interface PrivateRouteProps {
  component: React.FC;
}

const PrivateRoute: FC<PrivateRouteProps> = ({ component: Component }) => {
  const { user } = useAuth();

  return user ? <Component /> : <Navigate to="/login" />;
};

export default PrivateRoute;
