import { Modal } from "react-bootstrap";
import IMUserResponse from "../../../models/User/UserResponse";
import { useEffect, useState } from "react";
import IMConsultationResponse from "../../../models/Consultation/ConsultationResponse";
import { TableLocal } from "../../../components/TableLocal";

// ----------------------------------------------------------------------------
//                                  INTERFACES
// ----------------------------------------------------------------------------
interface IConsultationData {
  year: string;
  month: string;
  count: number;
}

interface IConsultationGeneralData {
  title: string;
  count: number;
}

// -------------------------------- Component Props
interface ISeeUserProps {
  handleClose: () => void;
  user?: IMUserResponse;
  consultations?: IMConsultationResponse;
  modelName: string;
}

export const ModalSeeUser: React.FC<ISeeUserProps> = ({
  handleClose,
  user,
  modelName,
  consultations,
}) => {
  const [transformedListByYearMonth, setTransformedListByYearMonth] = useState<
    IConsultationData[]
  >([]);
  const [transformedListGeneral, setTransformedListGeneral] = useState<
    IConsultationGeneralData[]
  >([]);

  useEffect(() => {
    const transformGeneralData = () => {
      if (consultations) {
        const {
          last_month,
          this_month,
          total,
        }: Pick<IMConsultationResponse, "last_month" | "this_month" | "total"> =
          consultations;
        const generalData: IConsultationGeneralData[] = [
          { title: "Total", count: total },
          { title: "Este mes", count: this_month },
          { title: "El mes pasado", count: last_month },
        ];

        setTransformedListGeneral(generalData);
      }
    };

    transformGeneralData();
  }, [consultations]);

  useEffect(() => {
    const transformData = () => {
      const byYearMonth = consultations?.by_year_month;
      const tempTransformedList: IConsultationData[] = [];

      if (byYearMonth) {
        Object.keys(byYearMonth)
          .sort((a, b) => parseInt(b) - parseInt(a)) // Sort years in descending order
          .forEach((year) => {
            const months = byYearMonth[year];
            Object.keys(months)
              .sort((a, b) => parseInt(b) - parseInt(a)) // Sort months in descending order
              .forEach((month) => {
                const monthName = new Date(
                  `${year}-${parseInt(month)}-15`
                ).toLocaleString("default", { month: "long" });
                tempTransformedList.push({
                  year,
                  month: monthName,
                  count: months[month],
                });
              });
          });
      }

      setTransformedListByYearMonth(tempTransformedList);
    };

    transformData();
  }, [consultations]);

  return (
    <>
      <Modal.Header
        className="row ms-1 me-1 pb-1 justify-content-center"
        closeButton
      >
        <Modal.Title>
          <div className="text-center">Información del {modelName}</div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="card">
          <div className="card-body">
            <div className="row">
              <p className="col-3 fw-bold">Nombre:</p>{" "}
              <p className="col border me-4 bg-light">
                {user ? user.name : "Error - Intente de nuevo"}
              </p>
            </div>
            <div className="row">
              <p className="col-3 fw-bold">Email:</p>{" "}
              <p className="col border me-4 bg-light">
                {user ? user.email : "Error - Intente de nuevo"}
              </p>
            </div>
            {/* <hr /> */}
            <div className="row">
              <p className="col-3 fw-bold">Rol:</p>{" "}
              <p className="col border me-4 bg-light">
                {user ? user.role?.name : "Error - Intente de nuevo"}
              </p>
            </div>
            <div className="row">
              <p className="col-3 fw-bold">Suscripción: </p>
              <p className="col border me-4 bg-light">
                {user ? user.subscription?.name : "Error - Intente de nuevo"}
              </p>
            </div>
            <hr />
            <div className="row justify-content-center mb-3 fw-bold">
              INFORMACIÓN DE CONSULTAS
            </div>
            <TableLocal
              list={transformedListGeneral}
              modelName={"Consultas"}
              colNames={[{ name: "" }, { name: "Nº" }]}
              infoColNumber={2}
              noAction={true}
              notSearchBar={true}
            />

            {consultations?.by_year_month && (
              <TableLocal
                list={transformedListByYearMonth}
                modelName={"Consultas"}
                colNames={[
                  { name: "año" },
                  { name: "mes" },
                  { name: "Nº consultas" },
                ]}
                infoColNumber={3}
                noAction={true}
                notSearchBar={true}
              />
            )}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-secondary" onClick={handleClose}>
          Cerrar
        </button>
      </Modal.Footer>
    </>
  );
};
