import { Dispatch, SetStateAction, useCallback } from "react";
import { Modal } from "react-bootstrap";

import IMUserResponse from "../../../../models/User/UserResponse";
import { api } from "../../../../services/api";
import Swal from "sweetalert2";

interface IDeleteUserProps {
  user?: IMUserResponse;
  setErase: Dispatch<SetStateAction<boolean>>;
  handleClose: () => void;
  loadData?: () => void;
  modelName: string;
}

export const DeleteUser: React.FC<IDeleteUserProps> = ({
  user,
  setErase,
  handleClose,
  loadData,
  modelName,
}) => {
  const handleDelete = useCallback(async () => {
    try {
      console.log(user, "user");
      if (user) {
        await api.delete(`admin/users/${user.id}`);
        setErase?.(false);
        handleClose();
        Swal.fire({
          title: "Usuario eliminado",
          text: "Usuario eliminado exitosamente",
          icon: "success",
        });
        const load = loadData;
        if (load) {
          loadData();
        }
      }
    } catch (err) {
      console.log(err);
    }
  }, [user, handleClose, loadData, setErase]);
  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title>Borrar Usuario</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          ¿Confirma que desea eliminar el {modelName.toLowerCase()} {user?.name}
          ?
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-secondary" onClick={handleClose}>
          Cerrar
        </button>
        <button
          className="btn btn-primary"
          type="button"
          onClick={handleDelete}
        >
          Confirmar
        </button>
      </Modal.Footer>
    </>
  );
};
